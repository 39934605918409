const HEADER_HEIGHT = 120;

export const smoothScroll = (scrollTopValue = 0): Promise<void> => {

    scrollTopValue = Math.round(scrollY + scrollTopValue - HEADER_HEIGHT);

    window.scrollTo({
        top: scrollTopValue,
        behavior: 'smooth'
    });

    return new Promise((resolve, reject) => {
        const failed = setTimeout(() => {
            reject('Could not scroll to desired height');
        }, 2000);

        const scrollHandler = () => {
            if (Math.round(scrollY) === scrollTopValue) {
                window.removeEventListener('scroll', scrollHandler);
                clearTimeout(failed);
                resolve();
            }
        };
        if (scrollY === scrollTopValue) {
            clearTimeout(failed);
            resolve();
        } else {
            window.addEventListener('scroll', scrollHandler);
        }
    });
}