import React from 'react';

import { NavArrow } from '../../../assets/icons/nav-arrow.svg';
import { ButtonVariantsEnum } from '../../../utils/_enums/button-variants.enum';
import {Button} from '../button/button';
import styles from './by-page-paginator.module.scss';
interface ByPagePaginatorProps {
    totalElements: number;
    pageNumber: number;
    pageSize: number;
    lastPage: number;
    prevButtonDisabled?: boolean;
    nextButtonDisabled?: boolean;
    setPageSettingsUpdate?: (pageNumber: number, pageSize: number) => void;
}

const ByPagePaginator = (props: ByPagePaginatorProps) => {

    const updatePageNumber = (newNumber: number): void => {
        if (
            props.setPageSettingsUpdate &&
            newNumber >= 0 &&
            newNumber !== props.pageNumber &&
            newNumber <= props.lastPage
        ) {
            props.setPageSettingsUpdate(
                newNumber,
                props.pageSize
            );
        }
    };

    const createPageNavigation = (): JSX.Element[] => {
        const {pageNumber, lastPage} = props;
        const renderNavBar = [];
        let variableCenterElement = -1;

        if (lastPage >= 2 ) variableCenterElement = Math.floor(lastPage / 2);
        if (pageNumber !== 0 && pageNumber !== lastPage) variableCenterElement = pageNumber;

        for (let i = 0; i <= lastPage; i++) {
            if (i === 0 || i === variableCenterElement || i === lastPage) {
                if (lastPage > 1 && i === lastPage && variableCenterElement <= lastPage-2) {
                    renderNavBar.push(<span className={`${styles['separator']}`}>...</span>);
                }
                if (pageNumber === i) {
                    renderNavBar.push(<span className={`${styles['current']}`} key={i}
                                            onClick={(): void => updatePageNumber(i)}>
                        {i + 1}
                    </span>);
                } else {
                    renderNavBar.push(<span className={`${styles['page-item']}`} key={i}
                                            onClick={(): void => updatePageNumber(i)}>
                        {i + 1}
                    </span>);
                }
                if (i === 0 && variableCenterElement > 1) {
                    renderNavBar.push(<span className={`${styles['separator']}`}>...</span>);
                } 
            }
        }
        return renderNavBar;
    };

    return (
        <div className={styles['by-page-paginator']}>
            <div className={styles['previous']}>
                    <Button
                        variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                        onClick={() => {
                            updatePageNumber(
                                props.pageNumber - 1
                            )
                        }}
                        disabled={props.prevButtonDisabled}
                        icon={{
                            element: (
                                <NavArrow />
                            ),
                            align: 'right'
                        }}
                    />
                </div>
                {createPageNavigation()}
                <div className={styles['next']}>
                    <Button
                        variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                        onClick={(): void =>
                            updatePageNumber(
                                props.pageNumber + 1
                            )
                        }
                        disabled={props.nextButtonDisabled}
                        icon={{
                            element: (
                                <NavArrow />
                            ),
                            align: 'right'
                        }}
                    />
                </div>
        </div>


    );
}

export default ByPagePaginator;
