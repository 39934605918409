import React, {useEffect, useState} from 'react';
import {useSwipeable} from 'react-swipeable';

import {BasicCardVariants} from '../../../utils/_enums/basic-card-variants.enum';
import {TypographyVariantsEnum} from '../../../utils/_enums/typeography-variants.enum';
import {smoothScroll} from '../../../utils/_helper/smooth-scrolling-helper';
import useWindowSize from '../../../utils/_hooks/useWindowSize.hook';
import {Pagination} from '../../../utils/_models/pagination';
import ByPagePaginator from '../by-page-paginator/by-page-paginator';
import {ErrorMessage} from '../error/error-message';
import TeaserButtons from '../teaser-buttons/teaser-buttons';
import {Typography} from '../typography/typography';
import styles from './teaser.module.scss';

interface TeaserProps {
    id?: string;
    headline: string;
    cards: React.ReactNode[];
    headlineElement?: string;
    headlineStyle?: string;
    headlineVariant?: TypographyVariantsEnum;
    onLoadNewItems: ({
                         size,
                         pageOrOffset
                     }: {
        size: number;
        pageOrOffset: number;
    }) => void;
    maxCards: number;
    type: BasicCardVariants;
    pagination?: Pagination;
    withNumbers?: boolean;
    hideTeaserButtons?: boolean;
    errorMessage?: string;
    inverted?: boolean;
    pending?: boolean;
    initialCardIndex?: number;
    resetOnChange?: number;
}

const Teaser = (props: TeaserProps): JSX.Element => {
    const [viewWidth] = useWindowSize();
    const ref = React.createRef<HTMLDivElement>();
    const [cardCount, setCardCount] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(props.initialCardIndex ?? 0);

    const isPrevDisabled = props.pagination
        ? props.pagination.page === 0
        : currentCardIndex === 0;

    const isNextDisabled = props.pagination
        ? (props.pagination.page + 1) * props.pagination.size >= props.maxCards
        : currentCardIndex + cardCount >= props.maxCards;

    const onPrev = async () => {
        if (props.pagination) {
            jumpToPage(props.pagination.page - 1);
        } else {
            if (ref.current) {
                try {
                    await smoothScroll(ref.current.getBoundingClientRect().top);
                } catch (e) {
                    console.warn(e);
                }
            }
            if (currentCardIndex >= cardCount) {
                props.onLoadNewItems({
                    size: 4,
                    pageOrOffset: currentCardIndex - cardCount
                });
                setCurrentCardIndex(currentCardIndex - cardCount);
            } else {
                props.onLoadNewItems({size: 4, pageOrOffset: 0});
                setCurrentCardIndex(0);
            }
        }
    };

    const onNext = async () => {
        if (props.pagination) {
            jumpToPage(props.pagination.page + 1);
        } else {
            if (ref.current) {
                try {
                    await smoothScroll(ref.current.getBoundingClientRect().top);
                } catch (e) {
                    console.warn(e);
                }
            }
            props.onLoadNewItems({
                size: 4,
                pageOrOffset: currentCardIndex + cardCount
            });
            setCurrentCardIndex(currentCardIndex + cardCount);
        }
    };

    const jumpToPage = async (pageNumber: number) => {
        if (props.pagination) {
            if (ref.current) {
                try {
                    await smoothScroll(ref.current.getBoundingClientRect().top);
                } catch (e) {
                    console.warn(e);
                }
            }
            props.onLoadNewItems({
                size: props.pagination.size,
                pageOrOffset: pageNumber
            });
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => (isNextDisabled ? undefined : onNext()),
        onSwipedRight: () => (isPrevDisabled ? undefined : onPrev())
    });

    const renderCards = () => {
        if (props.cards?.length > 0 && cardCount > 0) {
            const cardsToRender = props.pagination
                ? props.cards
                : props.cards.slice(0, cardCount);
            const lastRowCardCount =
                cardsToRender.length % cardCount > 0
                    ? cardsToRender.length % cardCount
                    : cardCount;
            return [
                ...cardsToRender,
                ...Array(cardCount - lastRowCardCount).fill(
                    <div className={styles['filler-card']}/>
                )
            ]
        } else {
            return undefined;
        }
    };

    useEffect(() => {
        if (props.resetOnChange) {
            setCurrentCardIndex(0);
        }
    }, [props.resetOnChange]);

    useEffect(() => {
        const width = ref.current?.clientWidth;
        let count;
        if (width) {
            switch (props.type) {
                case BasicCardVariants.BASIC_CARD_SMALL:
                    count = Math.floor((width + 20) / (311 + 20));
                    // console.log("count1: ", count);
                    // console.log("count1: ", width);
                    break;
                case BasicCardVariants.PURCHASE_CARD_NORMAL:
                    count = Math.floor((width + 20) / (311 + 20));
                    break;
                case BasicCardVariants.CREATOKEN_CARD:
                    count = Math.floor((width + 20) / (160 + 20));
                    break;
                default:
                    count = 0;
            }
            setCardCount(count);
        }
    }, [viewWidth]);

    return (
        <div
            id={props?.id}
            ref={ref}
            data-testid="teaser"
            className={`${styles['teaser']}
            ${props.pagination ? styles['vertical'] : ''}
            ${props.inverted ? styles['inverted'] : ''}
            `}
        >
            <Typography
                element={props.headlineElement}
                variant={
                    !props.headlineVariant
                        ? TypographyVariantsEnum.HEADING4_BOLD
                        : props.headlineVariant
                }
                inverted={props.inverted}
                style={{
                    textAlign: props.headlineStyle?.toLowerCase?.() as
                        | 'left'
                        | 'right'
                        | 'center'
                        | 'justify'
                        | undefined
                }}
            >
                {props.headline}
            </Typography>
            <div>
                <div
                    {...handlers}
                    className={`${styles['content']} ${styles[props.type]}`}
                >
                    {renderCards()}
                </div>
                <ErrorMessage className={styles['error-message']}>
                    {props.errorMessage}
                </ErrorMessage>
            </div>
            {!props.pagination ||
            Math.floor(props.maxCards / props.pagination.size) > 0 ? (
                <div className={styles['page-switch-wrapper']}>
                    {props.pagination && props.withNumbers ? (
                        <ByPagePaginator
                            pageSize={props.pagination?.size}
                            pageNumber={props.pagination.page}
                            lastPage={Math.floor(
                                props.maxCards / props.pagination.size
                            )}
                            totalElements={props.maxCards}
                            nextButtonDisabled={isNextDisabled}
                            prevButtonDisabled={isPrevDisabled}
                            setPageSettingsUpdate={(pageNumber: number) =>
                                jumpToPage(pageNumber)
                            }
                        />
                    ) : null}
                    {props.hideTeaserButtons
                        ? undefined
                        : <TeaserButtons
                            onClickNext={() => onNext()}
                            onClickPrev={() => onPrev()}
                            inverted={props.inverted}
                            nextButtonDisabled={isNextDisabled}
                            prevButtonDisabled={isPrevDisabled}
                            pending={props.pending}
                        />}
                </div>
            ) : null}
        </div>
    );
};

Teaser.defaultProps = {headlineElement: 'h3'};

export default Teaser;
