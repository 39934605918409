import React from 'react';

export const NavArrow: React.FC<React.SVGProps<SVGSVGElement>> = ({
    ...props
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.507" height="9.148" viewBox="0 0 16.507 9.148">
            <path id="Arrow_next" data-name="Arrow next" d="M12.091,9.148l4.416-4.613L11.986,0H9.709l3.526,3.677H0l1.87,1.8H13.234L9.709,9.148Z" transform="translate(0 0)"/>
        </svg>
    )
}




