import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';

import {ButtonVariantsEnum} from '../../../utils/_enums/button-variants.enum';
import {Button} from '../button/button';
import styles from './teaser-buttons.module.scss';

export interface TeaserButtonProps {
    onClickNext: () => void;
    onClickPrev: () => void;
    prevButtonDisabled?: boolean;
    nextButtonDisabled?: boolean;
    pending?: boolean;
    inverted?: boolean;
}

const TeaserButtons = (props: TeaserButtonProps): JSX.Element => {

    const [lastClick, setLastClick] = useState<'PREV' | 'NEXT' | undefined>();

    return (
        <div className={styles['nav-buttons']}>
            <div className={styles['next']}>
                <Button
                    variant={ButtonVariantsEnum.SECONDARY_FILLED}
                    onClick={() => {
                        setLastClick('NEXT');
                        props.onClickNext();
                    }}
                    inverted={props.inverted}
                    disabled={props.nextButtonDisabled || (lastClick === 'PREV' && props.pending)}
                    pending={lastClick === 'NEXT' ? props.pending : false}
                    icon={{
                        element: (
                            <FontAwesomeIcon
                                icon={['far', 'long-arrow-alt-right']}
                            />
                        ),
                        align: 'right'
                    }}
                />
            </div>
            <div className={styles['previous']}>
                <Button
                    variant={ButtonVariantsEnum.SECONDARY_OUTLINED}
                    onClick={() => {
                        setLastClick('PREV');
                        props.onClickPrev();
                    }}
                    inverted={props.inverted}
                    disabled={props.prevButtonDisabled || (lastClick === 'NEXT' && props.pending)}
                    pending={lastClick === 'PREV' ? props.pending : false}
                    icon={{
                        element: (
                            <FontAwesomeIcon
                                icon={['far', 'long-arrow-alt-left']}
                            />
                        ),
                        align: 'right'
                    }}
                />
            </div>
        </div>
    );
};

export default TeaserButtons;
